// ManageInviteCodes.tsx
import React, { useState, useEffect } from "react";
import { components } from "./api-types.gen";
import { useAxiosWrapper } from "./axiosWrapper";
import CreateInviteCodeWidget from "./CreateInviteCodeWidget";
import InviteCodesTable from "./InviteCodesTable";

type ListInviteCodesResponse = components["schemas"]["ListInviteCodesResponse"];
export type InviteCodeState = components["schemas"]["InviteCodeState"];
type IssueInviteCodeRequest = components["schemas"]["IssueInviteCodeRequest"];
type IssueInviteCodeResponse = components["schemas"]["IssueInviteCodeResponse"];

const ManageInviteCodes: React.FC = () => {
  const axiosWrapper = useAxiosWrapper();
  const [inviteCodes, setInviteCodes] = useState<InviteCodeState[]>([]);

  useEffect(() => {
    fetchInviteCodes();
  }, []);

  const fetchInviteCodes = async () => {
    const listInviteCodesResponse: ListInviteCodesResponse = await axiosWrapper(
      {
        method: "GET",
        url: "/api/invite-codes",
      }
    );
    setInviteCodes(listInviteCodesResponse.invite_codes);
  };

  const createInviteCode = async (
    description: string,
    daysUntilExpiration: number | undefined,
    maxUsages: number
  ) => {
    const requestBody: IssueInviteCodeRequest = {
      description,
      days_until_expiration: daysUntilExpiration,
      max_usages: maxUsages,
    };

    await axiosWrapper({
      method: "POST",
      url: "/api/invite-codes",
      data: requestBody,
    });
    fetchInviteCodes();
  };

  return (
    <div className="manage-invite-codes">
      <div className="text-box">
        <InviteCodesTable inviteCodes={inviteCodes} />
        <CreateInviteCodeWidget onSubmit={createInviteCode} />
      </div>
    </div>
  );
};

export default ManageInviteCodes;
