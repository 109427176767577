import React from "react";
import { Link } from "react-router-dom";
import GameList from "./GameList";
import "./App.scss";
import "./LoadGameForm.css";
import "./QuillmarkButton.scss";

interface LoadGameFormProps {
  user_uuid: string;
}

const LoadGameForm: React.FC<LoadGameFormProps> = ({ user_uuid }) => {
  return (
    <>
      <GameList
        owner_filter={user_uuid}
        phases_filter={["setup", "ready", "active"]}
      />

      <div className="back-button-container">
        <Link to="/" className="quillmark-button back-button">
          Back to main menu
        </Link>
      </div>
    </>
  );
};

export default LoadGameForm;
