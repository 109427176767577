import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import GoogleLogin from "./GoogleLogin";
import Rules from "./Rules";
import "./InvitationPage.css";
import { components } from "./api-types.gen";
import { useAxiosWrapper } from "./axiosWrapper";

interface RouteParams {
  [key: string]: string | undefined;
}

type CheckInviteCodeRequest = components["schemas"]["CheckInviteCodeRequest"];
type CheckInviteCodeResponse = components["schemas"]["CheckInviteCodeResponse"];

interface InvitationPageProps {
  onLogin: () => void;
}

const InvitationPage: React.FC<InvitationPageProps> = ({ onLogin }) => {
  const { uuid } = useParams<RouteParams>();
  const axiosWrapper = useAxiosWrapper();
  const [codeStatus, setCodeStatus] = useState<CheckInviteCodeResponse | null>(
    null
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (uuid) {
      checkInviteCode(uuid);
    }
  }, [uuid]);

  async function checkInviteCode(inviteCode: string) {
    setIsLoading(true);
    const request: CheckInviteCodeRequest = { invite_code: inviteCode };
    const response: CheckInviteCodeResponse = await axiosWrapper({
      method: "POST",
      url: "/api/check-invite-code",
      data: request,
    });

    setCodeStatus(response);
    setIsLoading(false);
  }

  const renderMessage = () => {
    if (isLoading) {
      return <p>Checking the invite code...</p>;
    }

    if (!codeStatus) return null;

    if (codeStatus.used_by_you) {
      return (
        <>
          <h2>Welcome back!</h2>
          <p>
            You have already used this invite code! You're all set, go ahead and
            enjoy the site.
          </p>
        </>
      );
    }

    if (!codeStatus.code_is_usable) {
      return (
        <p>
          Unfortunately, this is not a valid invite code. Please double-check
          the code or contact the site administrator for assistance.
        </p>
      );
    }

    return (
      <>
        <h2>Welcome!</h2>
        <p>
          You are already logged in and don't need an invite code. Feel free to
          explore and enjoy the site!
        </p>
      </>
    );
  };

  if (
    !isLoading &&
    codeStatus &&
    codeStatus.code_is_usable &&
    !codeStatus.already_logged_in
  ) {
    const signupData = {
      onLogin: onLogin,
      inviteCode: uuid || "",
    };

    return <Rules signupMode={signupData} />;
  }

  return <div className="invitation-page">{renderMessage()}</div>;
};

export default InvitationPage;
