// About.tsx

import React from "react";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect } from "react";
import { useAxiosWrapper } from "./axiosWrapper";
import { components } from "./api-types.gen";
import QuillmarkBox from "./QuillmarkBox";
import "./QuillmarkButton.scss";

type ResourceRef = components["schemas"]["ResourceRef"];

type AboutProps = {};

const About: React.FC<AboutProps> = () => {
  const navigate = useNavigate();
  const axiosWrapper = useAxiosWrapper();

  const handleBackToMainMenu = () => {
    navigate("/");
  };

  return (
    <QuillmarkBox className="quillmark-box-semitransparent">
      <h2>About Quillmark</h2>
      <p>
        Quillmark is an experimental app for AI-guided interactive storytelling.
      </p>

      <p>
        The model that powers the storyteller is{" "}
        <a href="https://openai.com/blog/chatgpt">ChatGPT 3.5</a>. The app
        attempts to construct prompts that channel the text-prediction abilities
        of the underlying model into working as an effective storyteller.
      </p>

      <p>
        As an experiment in AI maximalism, the app was also written with
        extensive assistance from GPT4. A lot of code was directly by GPT4,
        including most of the frontend. (The frontend was written in React, a
        framework Quillmark's human author was mostly-unfamiliar with at the
        beginning of the project and still is mostly unfamiliar with.)
      </p>

      <p>
        The art is all AI-generated with{" "}
        <a href="https://www.midjourney.com/home/">Midjourney</a>.
      </p>

      <button onClick={handleBackToMainMenu} className="quillmark-button">
        Back to main menu
      </button>
    </QuillmarkBox>
  );
};

export default About;
