import React from "react";
import ReactDOM from "react-dom";
import { HashRouter } from "react-router-dom";
import App from "./App";
import { ErrorContextProvider } from "./ErrorContext";
import * as Sentry from "@sentry/react";
import "./index.css";

if (process.env.SENTRY_FRONTEND_DSN) {
  Sentry.init({
    dsn: process.env.SENTRY_FRONTEND_DSN,
    environment: process.env.ENVIRONMENT,
    release: process.env.REACT_APP_VERSION_NUMBER,
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <ErrorContextProvider>
      <HashRouter>
        <App />
      </HashRouter>
    </ErrorContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
