import React, { useState, useEffect } from "react";
import axios from "axios";
import { components } from "./api-types.gen";
import "./CreateGameForm.css";
import { Link } from "react-router-dom";
import { useAxiosWrapper } from "./axiosWrapper";
import QuillmarkBox from "./QuillmarkBox";
import "./App.scss";

type StartNewGameRequest = components["schemas"]["StartNewGameRequest"];
type StartNewGameResponse = components["schemas"]["StartNewGameResponse"];
type GameInfo = components["schemas"]["GameInfo"];

const CreateGameForm: React.FC = () => {
  const axiosWrapper = useAxiosWrapper();
  const [characterName, setCharacterName] = useState("");
  const [characterGender, setCharacterGender] = useState<
    "male" | "female" | "non-binary" | undefined
  >(undefined);
  const [message, setMessage] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    startNewGame();
  }, []);

  const startNewGame = async () => {
    setIsDisabled(true);
    setMessage("Creating game...");

    const requestBody: StartNewGameRequest = {
      main_character_name: characterName || undefined,
      main_character_gender: characterGender || undefined,
    };

    try {
      const { data: startNewGameResponse } =
        await axios.post<StartNewGameResponse>(
          "/api/start-new-game",
          requestBody
        );
      const gameId = startNewGameResponse.game_id;

      setMessage(`Game created. Game ID: ${gameId}. Preparing game...`);

      const startTime = Date.now();
      const pollGameInfo = async () => {
        try {
          const { data: gameInfo } = await axios.get<GameInfo>(
            `/api/games/${gameId}/info`
          );

          if (gameInfo.ready) {
            window.location.href = `/#/game/${gameId}`;
          } else {
            const elapsedTime = Math.floor((Date.now() - startTime) / 1000);
            if (elapsedTime < 300) {
              setMessage(
                `Game created. Game ID: ${gameId}. Preparing game... (${elapsedTime}s)`
              );
              setTimeout(pollGameInfo, 5000);
            } else {
              setMessage("Game preparation timed out. Please try again.");
              setIsDisabled(false);
            }
          }
        } catch (error) {
          if (error instanceof Error) {
            setMessage(`Error: ${error.message}`);
          } else {
            setMessage(`Error: ${String(error)}`);
          }
          setIsDisabled(false);
        }
      };

      pollGameInfo();
    } catch (error) {
      if (error instanceof Error) {
        setMessage(`Error: ${error.message}`);
      } else {
        setMessage(`Error: ${String(error)}`);
      }
      setIsDisabled(false);
    }
  };

  return (
    <QuillmarkBox className="create-game-form">
      {message && (
        <div className="message-container">
          <span className="message">{message}</span>
        </div>
      )}
    </QuillmarkBox>
  );
};

export default CreateGameForm;
