import React, { useState, useEffect } from "react";
import { components } from "./api-types.gen";
import "./Setup.css";
import { useAxiosWrapper } from "./axiosWrapper";
import Spinner from "./Spinner";
import QuillmarkBox from "./QuillmarkBox";

type SetupInterfaceState = components["schemas"]["SetupInterfaceState"];

type SetupProps = {
  gameId: string;
  lastMessageId: string;
  setupState: SetupInterfaceState;
  writeAccess: boolean;
  refetchGameInterface: () => void;
};

const Setup: React.FC<SetupProps> = ({
  gameId,
  lastMessageId,
  setupState,
  refetchGameInterface,
  writeAccess,
}) => {
  const axiosWrapper = useAxiosWrapper();
  const [showCustomInput, setShowCustomInput] = useState(false);
  const [customText, setCustomText] = useState("");
  const [isLoading, setIsLoading] = useState(false); // Add this state

  useEffect(() => {
    setIsLoading(false);
    setShowCustomInput(false);
    setCustomText("");
  }, [setupState]);

  const handleButtonClick = async (value: string) => {
    setIsLoading(true); // Set loading state to true
    setShowCustomInput(false);
    try {
      await axiosWrapper({
        method: "POST",
        url: `/api/games/${gameId}/setup`,
        data: {
          setup_answer: value,
          last_event_uuid: lastMessageId,
        },
      });

      refetchGameInterface();
    } catch (error) {
      setIsLoading(false); // Set loading state to false in case of an error
      console.error("Error submitting the setup answer:", error);
    }
  };

  const handleCustomButtonClick = () => {
    setShowCustomInput(true);
  };

  const handleChooseCustomClick = () => {
    if (isValidCustomText) {
      handleButtonClick(customText);
    }
  };

  const handleBackClick = () => {
    setShowCustomInput(false);
  };

  const isValidCustomText = customText.length >= 1 && customText.length <= 100;

  return (
    <QuillmarkBox className="setup-container">
      {setupState.context_text.map((paragraph, index) => (
        <p key={index}>{paragraph.plain_text}</p>
      ))}
      {writeAccess && (
        <>
          {!showCustomInput ? (
            !isLoading ? ( // Conditionally render buttons or spinner
              <>
                <p className="current-question">
                  {setupState.current_question}
                </p>
                {setupState.suggested_answers.map((answer, index) => (
                  <span
                    key={index}
                    className="suggested-answer"
                    onClick={() => handleButtonClick(answer)}
                  >
                    {answer}
                  </span>
                ))}
                {!setupState.disallow_custom && (
                  <button
                    onClick={handleCustomButtonClick}
                    className="button-pill"
                  >
                    custom
                  </button>
                )}
              </>
            ) : (
              <Spinner />
            )
          ) : !isLoading ? ( // Conditionally render buttons or spinner
            <>
              <input
                type="text"
                value={customText}
                onChange={(e) => setCustomText(e.target.value)}
                className={`custom-input${
                  !isValidCustomText ? " invalid" : ""
                }`}
                placeholder={setupState.suggested_answers[0]}
              />

              <p>
                <button
                  onClick={handleChooseCustomClick}
                  className="button-pill"
                  disabled={!isValidCustomText}
                >
                  Choose!
                </button>
                <button onClick={handleBackClick} className="button-pill">
                  Back
                </button>
              </p>
            </>
          ) : (
            <Spinner />
          )}
        </>
      )}
    </QuillmarkBox>
  );
};

export default Setup;
