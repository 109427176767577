// ErrorNotification.tsx
import React, { useEffect, useState } from "react";
import { useErrorContext } from "./ErrorContext";

const ErrorNotification: React.FC = () => {
  const { error, setError } = useErrorContext();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (error) {
      setVisible(true);
      setTimeout(() => {
        setVisible(false);
        setError(null);
      }, 10000); // Auto-hide the error message after 3 seconds.
    }
  }, [error, setError]);

  if (!visible) {
    return null;
  }

  return (
    <div className="error-notification">
      {/* You can style this error message container */}
      {error?.message}
    </div>
  );
};

export default ErrorNotification;
