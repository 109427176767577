// ErrorContext.tsx
import { createContext, useContext, useState } from "react";

interface ErrorContextState {
  error: Error | null;
  setError: (error: Error | null) => void;
}

const ErrorContext = createContext<ErrorContextState>({
  error: null,
  setError: () => {},
});

export const useErrorContext = () => {
  return useContext(ErrorContext);
};

interface ErrorContextProviderProps {
  children: React.ReactNode;
}

export const ErrorContextProvider: React.FC<ErrorContextProviderProps> = ({
  children,
}) => {
  const [error, setError] = useState<Error | null>(null);

  return (
    <ErrorContext.Provider value={{ error, setError }}>
      {children}
    </ErrorContext.Provider>
  );
};
