import React, { useState } from "react";
import ThumbsUpIcon from "./symbols/ThumbsUp";
import ThumbsDownIcon from "./symbols/ThumbsDown";
import InvestigateIcon from "./symbols/InvestigateIcon";
import { useAxiosWrapper } from "./axiosWrapper";
import { useNavigate } from "react-router-dom";
import "./MessageChunk.css";
import "./Icons.scss";
import { components } from "./api-types.gen";

type EventRatingState = components["schemas"]["EventRatingState"];

interface MessageChunkProps {
  userInput: boolean;
  paragraphs: { plain_text: string }[];
  gameUUID: string;
  eventUUID: string;
  peekAccess: boolean;
  lastRating?: EventRatingState | null;
}

const MessageChunk: React.FC<MessageChunkProps> = ({
  userInput,
  paragraphs,
  gameUUID,
  eventUUID,
  peekAccess,
  lastRating = null,
}) => {
  const navigate = useNavigate();
  const [visibleRating, setVisibleRating] = useState(
    lastRating ? lastRating.rating : "not_rated"
  );
  const axiosWrapper = useAxiosWrapper();

  async function setRating(rating: "thumbs_up" | "thumbs_down" | "not_rated") {
    console.log("Set new rating for", eventUUID, rating);
    setVisibleRating(rating);
    await axiosWrapper({
      method: "POST",
      url: `/api/games/${gameUUID}/events/${eventUUID}/rate`,
      headers: { "Content-Type": "application/json" },
      data: {
        rating: rating,
      },
    });
  }

  async function handleThumbsUpClick() {
    if (visibleRating === "thumbs_up") {
      setRating("not_rated");
    } else {
      setRating("thumbs_up");
    }
  }

  async function handleThumbsDownClick() {
    if (visibleRating === "thumbs_down") {
      setRating("not_rated");
    } else {
      setRating("thumbs_down");
    }
  }

  async function handleInvestigateClick() {
    navigate(`/explain/games/${gameUUID}/events/${eventUUID}`);
  }

  return (
    <div className={`message-chunk ${userInput ? "user" : ""}`}>
      <div className="indicator-container">
        <span className="message-icon">{userInput ? "❯" : "❮"}</span>
      </div>
      <div className="message-content">
        {paragraphs.map((paragraph, index) => (
          <p key={index}>{paragraph.plain_text}</p>
        ))}
      </div>
      {!userInput && (
        <div className="action-icons">
          {peekAccess && (
            <InvestigateIcon
              className="icon-default"
              onClick={handleInvestigateClick}
            />
          )}

          <div onClick={handleThumbsUpClick}>
            <ThumbsUpIcon
              className={
                visibleRating === "thumbs_up" ? "icon-green" : "icon-default"
              }
            />
          </div>
          <div onClick={handleThumbsDownClick}>
            <ThumbsDownIcon
              className={
                visibleRating === "thumbs_down" ? "icon-red" : "icon-default"
              }
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default MessageChunk;
