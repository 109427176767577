// LoginPage.tsx

import React from "react";
import GoogleLogin from "./GoogleLogin";
import QuillmarkBox from "./QuillmarkBox";

interface LoginPageProps {
  onLogin: () => void;
}

const LoginPage: React.FC<LoginPageProps> = ({ onLogin }) => {
  return (
    <QuillmarkBox>
      <h2>Please log in below.</h2>
      <p>
        Be aware that the site is in closed beta. If you don't already have an
        account, you will be unable to make one without an invitation code.
      </p>
      <GoogleLogin onLogin={onLogin} />
    </QuillmarkBox>
  );
};

export default LoginPage;
