import React, { ReactNode } from "react";
import "./QuillmarkBox.scss";
import "./TextPage.css";

interface QuillmarkBoxProps {
  children: ReactNode;
  className?: string;
  contentClassName?: string;
}

const QuillmarkBox: React.FC<QuillmarkBoxProps> = ({
  children,
  className,
  contentClassName,
}) => {
  const baseClass = "quillmark-box";
  const combinedClassName = className ? `${baseClass} ${className}` : baseClass;

  const baseContentClass = "quillmark-box-content-wrapper main-content";
  const combinedContentClass = contentClassName
    ? `${baseContentClass} ${contentClassName}`
    : baseContentClass;

  return (
    <div className={combinedClassName}>
      <div className={combinedContentClass}>{children}</div>
      <div className="quillmark-box-box-decorations">
        <div className="embellishment top"></div>
        <div className="embellishment right"></div>
        <div className="embellishment bottom"></div>
        <div className="embellishment left"></div>
      </div>
    </div>
  );
};

export default QuillmarkBox;
