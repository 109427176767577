// CreateInviteCodeWidget.tsx
import React, { useState } from "react";

type CreateInviteCodeWidgetProps = {
  onSubmit: (
    description: string,
    daysUntilExpiration: number | undefined,
    maxUsages: number
  ) => void;
};

const CreateInviteCodeWidget: React.FC<CreateInviteCodeWidgetProps> = ({
  onSubmit,
}) => {
  const [description, setDescription] = useState<string>("");
  const [daysUntilExpiration, setDaysUntilExpiration] = useState<
    number | undefined
  >(undefined);
  const [maxUsages, setMaxUsages] = useState<number>(1);

  const handleSubmit = () => {
    onSubmit(description, daysUntilExpiration, maxUsages);
    setDescription("");
    setDaysUntilExpiration(undefined);
    setMaxUsages(1);
  };

  return (
    <div className="create-invite-code">
      <h2>Create New Invite Code</h2>
      <div className="form-group">
        <label htmlFor="description">Description:</label>
        <input
          type="text"
          id="description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label htmlFor="daysUntilExpiration">Days until expiration:</label>
        <input
          type="number"
          id="daysUntilExpiration"
          value={daysUntilExpiration || ""}
          onChange={(e) =>
            setDaysUntilExpiration(
              e.target.value ? parseInt(e.target.value) : undefined
            )
          }
        />
      </div>
      <div className="form-group">
        <label htmlFor="maxUsages">Max usages:</label>
        <input
          type="number"
          id="maxUsages"
          value={maxUsages}
          onChange={(e) => setMaxUsages(parseInt(e.target.value))}
        />
      </div>
      <div className="button-container">
        <button className="button" onClick={handleSubmit}>
          Create Invite Code
        </button>
      </div>
    </div>
  );
};

export default CreateInviteCodeWidget;
