// AuthContext.tsx
import React, { createContext, useState, useContext } from "react";

interface AuthContextData {
  isLoggedIn: boolean;
  bearerToken: string | null;
  email: string | null;
  setAuthData: (data: { bearerToken: string; email: string | null }) => void;
  clearAuthData: () => void;
}

const AuthContext = createContext<AuthContextData>({
  isLoggedIn: false,
  bearerToken: null,
  email: null,
  setAuthData: () => {},
  clearAuthData: () => {},
});

export const useAuth = () => useContext(AuthContext);

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [bearerToken, setBearerToken] = useState<string | null>(null);
  const [email, setEmail] = useState<string | null>(null);

  const setAuthData = (data: { bearerToken: string; email: string | null }) => {
    setIsLoggedIn(true);
    setBearerToken(data.bearerToken);
    setEmail(data.email);
  };

  const clearAuthData = () => {
    setIsLoggedIn(false);
    setBearerToken(null);
    setEmail(null);
  };

  return (
    <AuthContext.Provider
      value={{ isLoggedIn, bearerToken, email, setAuthData, clearAuthData }}
    />
  );
};
