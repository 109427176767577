import React, { useState, useEffect } from "react";
import GameListItem from "./GameListItem";
import { useNavigate } from "react-router-dom";
import "./GameList.css";
import { components } from "./api-types.gen";
import { useAxiosWrapper } from "./axiosWrapper";
import Spinner from "./Spinner";

type GameInfo = components["schemas"]["GameInfo"];
type GameInfos = components["schemas"]["GameInfos"];

interface GameListProps {
  owner_filter?: string;
  phases_filter?: string[];
}

const GameList: React.FC<GameListProps> = ({ owner_filter, phases_filter }) => {
  const axiosWrapper = useAxiosWrapper();
  const [games, setGames] = useState<GameInfo[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  useEffect(() => {
    fetchGames(owner_filter, phases_filter);
  }, [owner_filter, phases_filter]);

  async function fetchGames(ownerFilter?: string, phasesFilter?: string[]) {
    const queryParams = new URLSearchParams();
    if (ownerFilter) {
      queryParams.append("owner_user_uuid", ownerFilter);
    }
    if (phasesFilter) {
      queryParams.append("phases", phasesFilter.join(","));
    }

    const data: GameInfos = await axiosWrapper({
      method: "GET",
      url: `/api/games${
        queryParams.toString() ? "?" + queryParams.toString() : ""
      }`,
    });

    const sortedGames = data.games
      .filter((game) => game.ready)
      .sort((a, b) => {
        if (a.last_message_time == null && b.last_message_time == null)
          return 0;
        if (a.last_message_time == null) return 1;
        if (b.last_message_time == null) return -1;
        return b.last_message_time - a.last_message_time;
      });
    setGames(sortedGames);
    setIsLoading(false);
  }

  function handleGameClick(gameId: number | string) {
    navigate(`/game/${gameId}`);
  }

  return (
    <div className="game-list">
      {isLoading ? (
        <Spinner />
      ) : (
        games.map((game) => (
          <GameListItem
            key={game.game_id}
            game={game}
            handleGameClick={handleGameClick}
          />
        ))
      )}
    </div>
  );
};

export default GameList;
