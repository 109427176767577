// InviteCodesTable.tsx
import React from "react";
import { InviteCodeState } from "./ManageInviteCodes";
import InviteCodesTableRow from "./InviteCodesTableRow";

type InviteCodesTableProps = {
  inviteCodes: InviteCodeState[];
};

const InviteCodesTable: React.FC<InviteCodesTableProps> = ({ inviteCodes }) => {
  return (
    <div className="list-invite-codes">
      <h2>Existing Invite Codes</h2>
      {inviteCodes.map((inviteCode, index) => (
        <InviteCodesTableRow key={index} inviteCode={inviteCode} />
      ))}
    </div>
  );
};

export default InviteCodesTable;
