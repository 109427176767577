// InviteCodesTableRow.tsx
import React from "react";
import { InviteCodeState } from "./ManageInviteCodes";

type InviteCodesTableRowProps = {
  inviteCode: InviteCodeState;
};

const InviteCodesTableRow: React.FC<InviteCodesTableRowProps> = ({
  inviteCode,
}) => {
  return (
    <div className="invite-code">
      <p>Invite code: {inviteCode.invite_code}</p>
      <p>Description: {inviteCode.description || "N/A"}</p>
      {/* ...display other properties if needed */}
    </div>
  );
};

export default InviteCodesTableRow;
