import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  RefObject,
} from "react";
import "./App.scss";
import {
  Routes,
  Route,
  Link,
  useLocation,
  useNavigate,
} from "react-router-dom";
import MainMenu from "./MainMenu";
import Chat from "./Chat";
import CreateGameForm from "./CreateGameForm";
import LoadGameForm from "./LoadGameForm";
import BrowseGamesForm from "./BrowseGamesForm";
import GoogleLogin from "./GoogleLogin";
import GameInterface from "./GameInterface";
import ExplainEvent from "./ExplainEvent";
import InvitationPage from "./InvitationPage";
import ManageInviteCodes from "./ManageInviteCodes";
import ErrorNotification from "./ErrorNotification";
import LoginPage from "./LoginPage";
import Spinner from "./Spinner";
import { components } from "./api-types.gen";
import { useAxiosWrapper } from "./axiosWrapper";
import Rules from "./Rules";
import About from "./About";
import QuillmarkBox from "./QuillmarkBox";
import initialBackgroundImage from "./images/main-bg.jpg";

type WhoamiResponse = components["schemas"]["WhoamiResponse"];
type AccessLevel = components["schemas"]["AccessLevel"];
type ResourceRef = components["schemas"]["ResourceRef"];

const versionNumber = process.env.REACT_APP_VERSION_NUMBER;

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const axiosWrapper = useAxiosWrapper();
  const [whoami, setWhoami] = useState<WhoamiResponse | null>(null);
  const [backgroundImageUrl, setBackgroundImageUrl] = useState(
    initialBackgroundImage
  );
  const backgroundWrapperRef: RefObject<HTMLDivElement> = useRef(null);

  const changeBackgroundImage = (url: string | null) => {
    if (backgroundWrapperRef.current) {
      backgroundWrapperRef.current.style.backgroundImage = `url(${
        url ? url : initialBackgroundImage
      })`;
      backgroundWrapperRef.current.style.opacity = "1";
      setTimeout(() => {
        setBackgroundImageUrl(url ? url : initialBackgroundImage);
        if (backgroundWrapperRef.current) {
          backgroundWrapperRef.current.style.opacity = "0";
        }
      }, 1000); // Match this duration with the CSS transition duration
    }
  };

  const setRandomBackground = useCallback(async () => {
    const response: ResourceRef = await axiosWrapper({
      method: "GET",
      url: "/api/backgrounds/random",
    });

    const preloadImage = new Image();
    preloadImage.src = response.url;
    preloadImage.onload = () => {
      changeBackgroundImage(response.url);
    };
    preloadImage.onerror = () => {
      console.error("Failed to fetch image", response.url);
    };
  }, []);

  const fetchWhoami = useCallback(async () => {
    // Check if the current route is the "InvitationPage" route
    if (location.pathname.startsWith("/invites/")) {
      return;
    }

    const response: WhoamiResponse = await axiosWrapper({
      method: "GET",
      url: "/api/whoami",
    });
    setWhoami(response);

    if (!response.user_uuid) {
      navigate("/login");
    } else {
      await setRandomBackground();
    }
  }, []);

  useEffect(() => {
    fetchWhoami();
  }, [fetchWhoami]);

  return (
    <div
      className="app"
      style={{ backgroundImage: `url(${backgroundImageUrl})` }} // Move background image from main-area to app
    >
      <div className="background-wrapper" ref={backgroundWrapperRef}></div>

      <QuillmarkBox className="navbar quillmark-box-transparent">
        <Link to="/" className="logo">
          <img className="logo" src="/static/media/svg/logo.svg" alt="Logo" />
        </Link>
        {whoami && (
          <>
            {whoami.access_level === "admin" && (
              <span
                className="admin-emoji"
                role="img"
                aria-label="administrator"
              >
                👑
              </span>
            )}
          </>
        )}
        <div className="version-number" style={{ color: "#4a2d0e" }}>
          {versionNumber}
        </div>
      </QuillmarkBox>
      <div className={`main-area`}>
        <div className="version-number" style={{ color: "#4a2d0e" }}>
          {versionNumber}
        </div>
        <div className="block-container">
          <Routes>
            <Route
              path="/"
              element={
                <MainMenu
                  accessLevel={whoami?.access_level || "no_access"}
                  loading={whoami === null}
                />
              }
            />
            <Route path="/game/:gameId" element={<GameInterface />} />
            <Route
              path="/explain/games/:game_uuid/events/:event_uuid"
              element={<ExplainEvent />}
            />
            <Route
              path="/invites/:uuid"
              element={<InvitationPage onLogin={fetchWhoami} />}
            />
            <Route path="/create-game" element={<CreateGameForm />} />
            <Route
              path="/load-game"
              element={<LoadGameForm user_uuid={whoami?.user_uuid || ""} />}
            />
            <Route path="/browse-games" element={<BrowseGamesForm />} />
            <Route
              path="/login"
              element={<LoginPage onLogin={fetchWhoami} />}
            />
            <Route path="/rules" element={<Rules />} />
            <Route path="/about" element={<About />} />
            <Route
              path="/admin/manage-invites"
              element={<ManageInviteCodes />}
            />
          </Routes>
        </div>
      </div>
      <ErrorNotification />
    </div>
  );
}

export default App;
