import React from "react";
import "./GameListItem.css";
import { components } from "./api-types.gen";
import "./QuillmarkButton.scss";

type GameInfo = components["schemas"]["GameInfo"];

interface GameListItemProps {
  game: GameInfo;
  handleGameClick: (gameId: number | string) => void;
}

const GameListItem: React.FC<GameListItemProps> = ({
  game,
  handleGameClick,
}) => {
  const formattedDate = game.last_message_time
    ? new Date(game.last_message_time * 1000).toLocaleString()
    : "Unknown";

  return (
    <div
      className="quillmark-button"
      onClick={() => handleGameClick(game.game_id)}
    >
      {game.title && <h3 className="game-title">{game.title}</h3>}
      {!game.title && <h3 className="game-title">Game {game.game_id}</h3>}
      <p>
        {game.genre}
        {game.main_character_name && <span>, {game.main_character_name}</span>}
      </p>
      <p>
        {formattedDate}, {game.number_of_messages}
      </p>
    </div>
  );
};

export default GameListItem;
