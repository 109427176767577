// GoogleLogin.tsx
import React, { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { useAuth } from "./AuthContext";
import { components } from "./api-types.gen";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Spinner from "./Spinner";

type GoogleLoginRequest = components["schemas"]["GoogleLoginRequest"];
type GoogleLoginResponse = components["schemas"]["GoogleLoginResponse"];
type ServerConfigResponse = { google_client_id: string };

function isAxiosError(
  error: any
): error is { response: { data: { message: string } } } {
  return (
    error &&
    error.response &&
    error.response.data &&
    typeof error.response.data.message === "string"
  );
}

declare global {
  interface Window {
    google: any;
  }
}

interface GoogleLoginProps {
  inviteCode?: string;
  onLogin: () => void;
}

const GoogleLogin: React.FC<GoogleLoginProps> = ({ inviteCode, onLogin }) => {
  const { setAuthData } = useAuth();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [googleClientId, setGoogleClientId] = useState<string | null>(null);

  useEffect(() => {
    const fetchGoogleClientId = async () => {
      try {
        const { data } = await axios.get<ServerConfigResponse>(
          "/api/configuration"
        );
        setGoogleClientId(data.google_client_id);
      } catch (error) {
        setErrorMessage("Failed to fetch Google Client ID. Please try again.");
      }
    };

    fetchGoogleClientId();
  }, []);

  useEffect(() => {
    if (googleClientId) {
      window.google.accounts.id.initialize({
        client_id: googleClientId,
        callback: handleCredentialResponse,
        auto_prompt: false,
      });
      window.google.accounts.id.renderButton(
        document.getElementById("google-login-button"),
        {
          theme: "outline",
          size: "large",
          text: "continue_with",
          shape: "pill",
        }
      );
    }
  }, [googleClientId]);

  const handleCredentialResponse = async (response: any) => {
    const credential = response.credential;

    const requestBody: GoogleLoginRequest = {
      id_token: credential,
      invite_code: inviteCode,
    };

    try {
      const { data: resp } = await axios.post<GoogleLoginResponse>(
        "/auth/google/login",
        requestBody
      );
      setAuthData({ bearerToken: resp.session_id, email: resp.email || null });
      onLogin();
      navigate("/"); // Navigate to the main menu
    } catch (error) {
      setErrorMessage(
        isAxiosError(error)
          ? error.response.data.message
          : "An error occurred. Please try again."
      );
    }
  };

  return (
    <>
      {googleClientId ? <div id="google-login-button" /> : <Spinner />}
      {errorMessage && <div className="error-message">{errorMessage}</div>}
    </>
  );
};

export default GoogleLogin;
