import axios, { AxiosRequestConfig, AxiosError } from "axios";
import { useErrorContext } from "./ErrorContext";
import { useNavigate } from "react-router-dom";

export function useAxiosWrapper() {
  const { setError } = useErrorContext();
  const navigate = useNavigate();

  async function axiosWrapper<T>(config: AxiosRequestConfig): Promise<T> {
    try {
      const response = await axios(config);
      return response.data;
    } catch (error) {
      console.error("Error message:", error);

      const axiosError = error as AxiosError;
      if (axiosError.response && axiosError.response.status === 403) {
        navigate("/login");
      } else {
        if (axiosError instanceof Error) {
          setError(new Error(axiosError.message));
        } else {
          setError(
            new Error("An error occurred while processing the request.")
          );
        }
      }

      throw error;
    }
  }

  return axiosWrapper;
}
