import React from "react";
import { Link } from "react-router-dom";
import GameList from "./GameList";
import "./App.scss";
import "./BrowseGamesForm.css";
import "./QuillmarkButton.scss";

interface BrowseGamesFormProps {}

const BrowseGamesForm: React.FC<BrowseGamesFormProps> = () => {
  return (
    <>
      <GameList phases_filter={["active"]} />

      <div className="back-button-container">
        <Link to="/" className="quillmark-button back-button">
          Back to main menu
        </Link>
      </div>
    </>
  );
};

export default BrowseGamesForm;
