import React, { useEffect, useState } from "react";
import { useParams } from "./hooks";
import { components } from "./api-types.gen";
import { useAxiosWrapper } from "./axiosWrapper";
import QuillmarkBox from "./QuillmarkBox";
import "./ExplainEvent.scss";

type ExplainEventResponse = components["schemas"]["ExplainEventResponse"];
type Message = components["schemas"]["Message"];

interface ExplainResponseProps {}

interface RouteParams {
  game_uuid: string;
  event_uuid: string;
}

const ExplainEvent: React.FC<ExplainResponseProps> = () => {
  const { game_uuid, event_uuid } = useParams<RouteParams>();
  const axiosWrapper = useAxiosWrapper();
  const [explainEventResponse, setExplainEventResponse] =
    useState<ExplainEventResponse | null>(null);

  useEffect(() => {
    async function makeRequest() {
      const data: ExplainEventResponse = await axiosWrapper({
        method: "GET",
        url: `/api/games/${game_uuid}/events/${event_uuid}/explain`,
      });

      setExplainEventResponse(data);
    }

    if (!explainEventResponse) {
      makeRequest();
    }
  }, []);

  if (!explainEventResponse) {
    return <div>Loading...</div>;
  }

  return (
    <QuillmarkBox className="explain-box" contentClassName="explain-container">
      {explainEventResponse.consultations.map((consultation, index) => (
        <div key={index} className="explain-consultation">
          <h3>
            Consultation {index + 1} - {consultation.metadata.purpose}
          </h3>
          <div className="explain-messages-container">
            <div className="explain-messages">
              {consultation.request.map((message, i) => (
                <div
                  key={i}
                  className={`explain-message explain-message-${message.role} explain-message-request`}
                >
                  {message.content.split("\n\n").map((paragraph, idx) => (
                    <p key={idx}>
                      {paragraph.split("\n").map((line, lineIdx) => (
                        <>
                          {lineIdx > 0 && <br />}
                          {line}
                        </>
                      ))}
                    </p>
                  ))}
                </div>
              ))}
              {consultation.response.map((message, i) => (
                <div
                  key={i}
                  className={`explain-message explain-message-${message.role} explain-message-response`}
                >
                  {message.content.split("\n\n").map((paragraph, idx) => (
                    <p key={idx}>
                      {paragraph.split("\n").map((line, lineIdx) => (
                        <>
                          {lineIdx > 0 && <br />}
                          {line}
                        </>
                      ))}
                    </p>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </QuillmarkBox>
  );
};

export default ExplainEvent;
