// Rules.tsx

import React from "react";
import { useNavigate } from "react-router-dom";
import GoogleLogin from "./GoogleLogin";
import QuillmarkBox from "./QuillmarkBox";
import "./TextPage.css";
import "./QuillmarkButton.scss";

interface SignupData {
  onLogin: () => void;
  inviteCode: string;
}

interface RulesProps {
  signupMode?: SignupData;
}

const Rules: React.FC<RulesProps> = ({ signupMode }) => {
  const navigate = useNavigate();

  const handleBackToMainMenu = () => {
    navigate("/");
  };

  return (
    <QuillmarkBox className="text-box">
      <div className="main-content">
        {!!signupMode ? (
          <>
            <h2>Review terms and conditions before signup</h2>

            <h3>Welcome!</h3>

            <p>
              Quillmark is an app for AI-guided interactive storytelling. It
              uses large language models to create a setting and a story for you
              to navigate your way through, similar to a choose-your-own
              adventure or a role-playing game.
            </p>

            <p>You've been invited to try it out!</p>

            <p>
              Before you do so, however, please review our terms and conditions
              below. Create an account &mdash; by logging in with a Google
              account &mdash; only if you accept these terms and conditions.
            </p>
          </>
        ) : (
          <>
            <h2>Terms and conditions</h2>
          </>
        )}

        <h3>Content restrictions</h3>
        <p>
          The{" "}
          <a href="https://openai.com/policies/usage-policies">
            OpenAI usage policies
          </a>{" "}
          must be obeyed in the content submitted. These are rules set by
          OpenAI, not by Quillmark. These restrictions are necessary for
          Quillmark to be able to use OpenAI's services as a backend.
        </p>

        <p>
          In short, this means:
          <ul>
            <li>no sex</li>
            <li>
              no explicit violence (the sort of violence commonly seen in genre
              literature and role-playing games seems to be okay -- don't try to
              push the boundaries)
            </li>
            <li>
              no attempts to jailbreak the underlying model or make it say
              offensive or controversial things
            </li>
          </ul>
        </p>

        <p>
          Refer to the{" "}
          <a href="https://openai.com/policies/usage-policies">
            OpenAI usage policies
          </a>{" "}
          for a complete list.
        </p>

        <h3>Irrevocable usage rights to content</h3>

        <p>
          The intent is to use the content submitted to improve the prompt
          engineering and the service in general. The content will also in
          general be served <b>publicly</b>. There should be no expectation of
          privacy on Quillmark. Do not enter private or sensitive content.
        </p>

        <p>
          The user irrevocably grants the service owner a right to use the
          content submitted into the interactive stories. Your contributions and
          the AI responses to them may be:
          <ul>
            <li>displayed publicly</li>
            <li>used for training or fine-tuning</li>
            <li>used for debugging</li>
            <li>used to improve the service</li>
            <li>used for any other purpose</li>
          </ul>
        </p>

        <h3>Account information</h3>

        <p>
          Quillmark handles your login information to allow you to log in
          through third-party identity services (e.g. Google). This includes
          handling your email and your third-party account ID.
        </p>

        <p>
          As a privacy precaution, if your account becomes inactive, we will
          delete this information. This effectively disables and anonymizes your
          account. (If you wish, you may then sign up for another account.)
        </p>
      </div>

      {!!signupMode ? (
        <>
          <GoogleLogin
            inviteCode={signupMode.inviteCode}
            onLogin={signupMode.onLogin}
          />
        </>
      ) : (
        <>
          <button onClick={handleBackToMainMenu} className="quillmark-button">
            Back to main menu
          </button>
        </>
      )}
    </QuillmarkBox>
  );
};

export default Rules;
