import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import "./GameInterface.css";
import { components } from "./api-types.gen";
import { useAxiosWrapper } from "./axiosWrapper";
import Chat from "./Chat";
import Setup from "./Setup";
import Spinner from "./Spinner";

type GameInterfaceResponse = components["schemas"]["GameInterfaceResponse"];
type ChatInterfaceState = components["schemas"]["ChatInterfaceState"];
type SetupInterfaceState = components["schemas"]["SetupInterfaceState"];

enum GameInterfaceMode {
  Loading,
  Error,
  Chat,
  Setup,
}

const GameInterface: React.FC = () => {
  const { gameId } = useParams<{ gameId: string }>();
  const axiosWrapper = useAxiosWrapper();
  const [mode, setMode] = useState<GameInterfaceMode>(
    GameInterfaceMode.Loading
  );
  const [response, setResponse] = useState<GameInterfaceResponse | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [incomingResponse, setIncomingResponse] =
    useState<GameInterfaceResponse | null>(null);

  useEffect(() => {
    fetchGameInterface();
  }, []);

  useEffect(() => {
    if (!incomingResponse) {
      return;
    }

    const data: GameInterfaceResponse =
      incomingResponse as GameInterfaceResponse;

    const hasNonNullProperty = (
      obj: GameInterfaceResponse,
      property: "chat" | "setup"
    ): boolean => {
      return obj[property] !== null && obj[property] !== undefined;
    };

    const nonNullProperties = (
      ["chat", "setup"] as Array<"chat" | "setup">
    ).filter((property) => hasNonNullProperty(data, property)).length;

    if (nonNullProperties !== 1) {
      setErrorMessage("Invalid game state data received from the server.");
      setMode(GameInterfaceMode.Error);
    } else if (data.setup !== null) {
      setMode(GameInterfaceMode.Setup);
    } else {
      setMode(GameInterfaceMode.Chat);
    }

    setResponse(data);

    setIncomingResponse(null);
  }, [incomingResponse]);

  const fetchGameInterface = useCallback(async () => {
    try {
      const data: GameInterfaceResponse = await axiosWrapper({
        method: "GET",
        url: `/api/games/${gameId}`,
      });

      setIncomingResponse(data);
    } catch (error) {
      setErrorMessage(
        "An error occurred while fetching the game interface data."
      );
      setMode(GameInterfaceMode.Error);
    }
  }, [axiosWrapper, gameId]);

  function renderContent() {
    if (mode === GameInterfaceMode.Loading) {
      return <Spinner />;
    }

    if (mode === GameInterfaceMode.Error) {
      return <div className="error-screen">{errorMessage}</div>;
    }

    if (mode === GameInterfaceMode.Chat && response) {
      return (
        <Chat
          gameId={response.game_id}
          lastMessageId={response.last_message_id}
          chatState={response.chat as ChatInterfaceState}
          refetchGameInterface={fetchGameInterface}
          writeAccess={response.write_access}
          peekAccess={response.peek_access}
        />
      );
    }

    if (mode === GameInterfaceMode.Setup && response) {
      return (
        <Setup
          gameId={response.game_id}
          lastMessageId={response.last_message_id}
          setupState={response.setup as SetupInterfaceState}
          refetchGameInterface={fetchGameInterface}
          writeAccess={response.write_access}
        />
      );
    }
  }

  return <div className="game-interface">{renderContent()}</div>;
};

export default GameInterface;
